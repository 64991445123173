body {
  margin: 0;
  font-family: 'UniversTE20-Thin', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow: hidden;
}

#root {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'UniversTE20-Thin';
  src: url('/public/font/UniversTE20-Thin.eot');
  src: url('/public/font/UniversTE20-Thin.eot?#iefix')format('embedded-opentype'),
  url('/public/font/UniversTE20-Thin.woff2')format('woff2'),
  url('/public/font/UniversTE20-Thin.woff')format('woff'),
  url('/public/font/UniversTE20-Thin.ttf')format('truetype'),
  url('/public/font/UniversTE20-Thin.svg#UniversTE20-Thin')format('svg');
}

@font-face {
  font-family: 'VCROSD-Mono';
  src: url('/public/font/VCR_OSD_MONO_1.001.ttf')format('truetype');
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
